<template>
  <div class="navbar">
    <div class="navbar_item" v-for="(item, index) in items" :key="index" :to="item.to" @click="navbarChange(index, item.to, item.text)">
      <span class="icon">
        <div :class="{'circle': navbarIndex == index}">
          <img :class="{'activeIcon': navbarIndex == index}" :src="navbarIndex == index ? item.activeIcon : item.normalIcon" />
        </div>
      </span>
      <span>{{item.text}}</span>
    </div>
  </div>
</template>

<script>
import mixin from '../common/mixin/mixins'
export default {
  data() {
    return {
      items: [{
        text: '首页',
        to: '/home',
        normalIcon: require('../assets/images/home_tab_sel_icon@2x.png'),
        activeIcon: require('../assets/images/home_active_icon@2x.png')
      },
      {
        text: '技能培训',
        to: '/skillTraining',
        normalIcon: require('../assets/images/tech_tab_sel_icon@2x.png'),
        activeIcon: require('../assets/images/tech_active_icon@2x.png')
      },
      // {
      //   text: '缝线导图',
      //   to: '/home',
      //   normalIcon: require('../assets/images/fxdt_tab_sel_icon@2x.png'),
      //   activeIcon: require('../assets/images/fxdt_active_icon@2x.png')
      // },
      {
        text: '个人中心',
        to: '/myMain',
        normalIcon: require('../assets/images/fxdt_tab_personal_icon@2x.png'),
        activeIcon: require('../assets/images/my_active_icon@2x.png')
      }]
    }
  },
  mixins: [mixin],
  computed: {
    navbarIndex() {
      return this.$store.state.navbarIndex;
    }
  },
  methods: {
    navbarChange(index, url, name) {
      this.BuriedPoint(1, name, '', url);
      // this.$store.commit('changeNavbarIndex', index);
      this.$router.push(url);
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  z-index: 9999;
  .navbar_item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    font-size: 12px;
    color: #333333;
    .icon{
      position: relative;
      width: 24px;
      height: 24px;
      margin-bottom: 3px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .circle {
      position: absolute;
      top: -18px;
      left: -10px;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      border-radius: 50%;
      background-color: #FFFFFF;
      img {
        position: absolute;
        top: 5.5px;
        width: 40px;
        height: 40px;
      }
    }
  }
}
</style>