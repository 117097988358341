export function dateFilter(time) {    
  if (time) {
    let date = time.split("T");
    return date[0];
  }
}

export function dateFilter2(dt) {
  if (dt) {
    let date = new Date(dt);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? '0' + MM : MM;
    let d = date.getDate();
    d = d < 10 ? '0' + d : d;
    let h = date.getHours();
    h = h < 10 ? '0' + h : h;
    let m = date.getMinutes();
    m = m < 10 ? '0' + m : m;
    let s = date.getSeconds();
    s = s < 10 ? '0' + s : s;
    return y + '-' + MM + '-' + d + ' ' + h + ':' + m;
  }
  return null;
}

export function timeQuantum(startTime, endTime) {
  if (startTime && endTime) {
    let startDate = new Date(startTime);
    let startYear = startDate.getFullYear();
    let startMonth = startDate.getMonth() + 1;
    let startMonthFormat = startMonth < 10 ? '0' + startMonth : startMonth;
    let startDay = startDate.getDate();
    let startDayFormat = startDay < 10 ? '0' + startDay : startDay;
    let startHours = startDate.getHours();
    let startHoursFormat = startHours < 10 ? '0' + startHours : startHours;
    let startMinutes = startDate.getMinutes();
    let startMinutesFormat = startMinutes < 10 ? '0' + startMinutes : startMinutes;

    let endDate = new Date(endTime);
    let endYear = endDate.getFullYear();
    let endMonth = endDate.getMonth() + 1;
    let endMonthFormat = endMonth < 10 ? '0' + endMonth : endMonth;
    let endDay = endDate.getDate();
    let endDayFormat = endDay < 10 ? '0' + endDay : endDay;
    let endHours = endDate.getHours();
    let endHoursFormat = endHours < 10 ? '0' + endHours : endHours;
    let endMinutes = endDate.getMinutes();
    let endMinutesFormat = endMinutes < 10 ? '0' + endMinutes : endMinutes;

    if(startYear == endYear && startMonth == endMonth && startDay == endDay) {
      return startYear + '.' + startMonthFormat + '.' + startDayFormat + ' ' + startHoursFormat + ':' + startMinutesFormat + ' - ' + endHoursFormat + ':' + endMinutesFormat;
    } else {
      return startYear + '.' + startMonthFormat + '.' + startDayFormat + ' ' + startHoursFormat + ':' + startMinutesFormat + ' - ' + endYear + '.' + endMonthFormat + '.' + endDayFormat + ' ' + endHoursFormat + ':' + endMinutesFormat;
    }
  } else {
    return '';
  }
}

export default { dateFilter, dateFilter2, timeQuantum }
