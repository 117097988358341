import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import storage from "../common/utils/storage";

var u = navigator.userAgent;
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
if (isiOS && !window.entryLink) {
  window.entryLink = encodeURIComponent(window.location.href.split("#")[0]);
}

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

/*
isRoutes: true为必选项
*/
const routes = [
  {
    path: "/",
    redirect: 'transferPage',
  },
  {
    path: "/transferPage",
    name: "TransferPage",
    component: () => import("@/views/TransferPage/index"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/authorize",
    name: "Authorize",
    component: () => import("@/views/Authorize/Authorize"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/entrance",
    name: "Entrance",
    component: () => import("@/views/entrance/entrance"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/login"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/JNJUser",
    name: "JNJUser",
    component: () => import("@/views/JNJUser/JNJUser"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/openJNJUser",
    name: "OpenJNJUser",
    component: () => import("@/views/JNJUser/openJNJUser"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/register/register"),
    meta: {
      navShow: false,
      role: false,
      keepAlive: true,
      isRoutes: true
    }
  },
  {
    path: "/publicRegister",
    name: "PublicRegister",
    component: () => import("@/views/publicRegister/publicRegister"),
    meta: {
      navShow: false,
      role: false,
      keepAlive: true,
      isRoutes: true
    }
  },
  {
    path: "/interest",
    name: "AreaOfInterest",
    component: () => import("@/views/AreaOfInterest/AreaOfInterest"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/home"),
    meta: {
      navShow: true,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/blank",
    name: "blank",
    component: () => import("@/views/home/blank"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/home/search"),
    meta: {
      navShow: false,
      role: true,
      keepAlive: false,
      isRoutes: true
    }
  },
  {
    path: "/contentPage",
    name: "Content",
    component: () => import("@/views/ContentPage/ContentPage"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/contentyl",
    name: "Contentyl",
    component: () => import("@/views/ContentPage/Contentyl"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () => import("@/views/contactUs/contactUs"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/department",
    name: "Department",
    component: () => import("@/views/department/department"),
    meta: {
      navShow: true,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/myMain",
    name: "MyMain",
    component: () => import("@/views/mine/myMain"),
    meta: {
      navShow: true,
      role: true,
      isRoutes: true,
      title: '个人中心'
    }
  },
  {
    path: "/myFavorite",
    name: "MyFavorite",
    component: () => import("@/views/mine/myFavorite"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '我的点赞'
    }
  },
  {
    path: "/myCollection",
    name: "MyCollection",
    component: () => import("@/views/mine/myCollection"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '我的收藏'
    }
  },
  {
    path: "/myMeeting",
    name: "MyMeeting",
    component: () => import("@/views/mine/myMeeting"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '我的会议'
    }
  },
  {
    path: "/browseRecords",
    name: "BrowseRecords",
    component: () => import("@/views/mine/browseRecords"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '浏览记录'
    }
  },
  {
    path: "/myQRcode",
    name: "MyQRcode",
    component: () => import("@/views/mine/myQRcode"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/myInvitation",
    name: "MyInvitation",
    component: () => import("@/views/mine/myInvitation"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/liveList",
    name: "LiveList",
    component: () => import("@/views/liveList/liveList"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '直播会议'
    }
  },
  {
    path: "/skillTraining",
    name: "SkillTraining",
    component: () => import("@/views/skillTraining/index"),
    meta: {
      navShow: true,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/skillTrainingList",
    name: "SkillTrainingList",
    component: () => import("@/views/skillTraining/list"),
    meta: {
      navShow: true,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/skillTrainingLiveList",
    name: "SkillTrainingLiveList",
    component: () => import("@/views/skillTraining/liveList"),
    meta: {
      navShow: true,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/digitalSummit",
    name: "DigitalSummit",
    component: () => import("@/views/DigitalSummit/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/digitalSummitList",
    name: "DigitalSummitList",
    component: () => import("@/views/DigitalSummit/list"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/suturePrefecture",
    name: "SuturePrefecture",
    component: () => import("@/views/suturePrefecture/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '缝合新材料专区'
    }
  },
  {
    path: "/sutureList",
    name: "SutureList",
    component: () => import("@/views/suturePrefecture/list"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
    }
  },
  {
    path: "/SSIPrefecture",
    name: "SSIPrefecture",
    component: () => import("@/views/SSIPrefecture/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '预防手术切口感染(SSI)专区'
    }
  },
  {
    path: "/SSIList",
    name: "SSIList",
    component: () => import("@/views/SSIPrefecture/list"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
    }
  },
  {
    path: "/default",
    name: "Default",
    component: () => import("@/views/default/index"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true,
    }
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("@/views/default/test"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true,
    }
  },
  {
    path: "/termsOfUse",
    name: "TermsOfUse",
    component: () => import("@/views/TermsOfUse/index"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true,
    }
  },
  {
    path: "/privacyPolicy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy/index"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true,
    }
  },
  {
    path: "/powerAttorney",
    name: "PowerAttorney",
    component: () => import("@/views/PowerAttorney/index"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true,
    }
  },
  {
    path: "/sutureLean",
    name: "SutureLean",
    component: () => import("@/views/sutureLean/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '缝合精益化'
    }
  },
  {
    path: "/transition",
    name: "Transition",
    component: () => import("@/views/default/transition"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/channel",
    name: "channel",
    component: () => import("@/views/default/channel"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/liveEntry",
    name: "LiveEntry",
    component: () => import("@/views/default/liveEntry"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/transferJNJUser",
    name: "TransferJNJUser",
    component: () => import("@/views/default/transferJNJUser"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/newProduct",
    name: "NewProduct",
    component: () => import("@/views/newProduct/newProduct"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/zxbCompetition",
    name: "zxbCompetition",
    component: () => import("@/views/zxbCompetition/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/zxbSliderList",
    name: "zxbSliderList",
    component: () => import("@/views/zxbCompetition/sliderList"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/zxbList",
    name: "zxbList",
    component: () => import("@/views/zxbCompetition/list"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/zxbPoster",
    name: "zxbPoster",
    component: () => import("@/views/zxbCompetition/poster"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/enroll",
    name: "Enroll",
    component: () => import("@/views/zxbCompetition/enroll"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/caseCompetitionArea",
    name: "CaseCompetitionArea",
    component: () => import("@/views/CaseCompetitionArea/caseCompetitionArea"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/caseCompetitionList",
    name: "CaseCompetitionList",
    component: () => import("@/views/CaseCompetitionArea/list"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/extracardiacPrefecture",
    name: "ExtracardiacPrefecture",
    component: () => import("@/views/ExtracardiacPrefecture/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/myWorks",
    name: "MyWorks",
    component: () => import("@/views/ExtracardiacPrefecture/myWorks"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/uploadWorks",
    name: "UploadWorks",
    component: () => import("@/views/ExtracardiacPrefecture/uploadWorks"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/worksDetail",
    name: "WorksDetail",
    component: () => import("@/views/ExtracardiacPrefecture/worksDetail"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/operativeMonth",
    name: "OperativeMonth",
    component: () => import("@/views/OperativeMonth/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/operativeMonthList",
    name: "OperativeMonthList",
    component: () => import("@/views/OperativeMonth/list"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/operativeMonthIntroduce",
    name: "OperativeMonthIntroduce",
    component: () => import("@/views/OperativeMonth/introduce"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/plus20",
    name: "Plus20",
    component: () => import("@/views/Plus20/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/questionnaire",
    name: "Questionnaire",
    component: () => import("@/views/Other/questionnaire"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/SSIquestionnaire",
    name: "SSIquestionnaire",
    component: () => import("@/views/Other/SSIquestionnaire"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/endoscopeCompetition",
    name: "EndoscopeCompetition",
    component: () => import("@/views/EndoscopeCompetition/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/challengePoster",
    name: "ChallengePoster",
    component: () => import("@/views/EndoscopeCompetition/challengePoster"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/productFeedback",
    name: "ProductFeedback",
    component: () => import("@/views/ProductFeedback/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '缝线产品反馈'
    }
  },
  {
    path: "/productFeedbackList",
    name: "ProductFeedbackList",
    component: () => import("@/views/ProductFeedback/list"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '缝线产品反馈'
    }
  },
  {
    path: "/productFeedbackDetails",
    name: "ProductFeedbackDetails",
    component: () => import("@/views/ProductFeedback/details"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '缝线产品反馈'
    }
  },
  {
    path: "/socialResponsibility",
    name: "SocialResponsibility",
    component: () => import("@/views/Other/socialResponsibility"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/electronicPhoto",
    name: "ElectronicPhoto",
    component: () => import("@/views/ElectronicPhoto/index"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/activityZone",
    name: "ActivityZone",
    component: () => import("@/views/ActivityZone/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/activityDetail",
    name: "ActivityDetail",
    component: () => import("@/views/ActivityZone/detail"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/resourceInterpret",
    name: "ResourceInterpret",
    component: () => import("@/views/ActivityZone/resourceInterpret"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // console.log(to, from)
  if (to.path != '/authorize') {
    storage.setStore('lastPath', to.fullPath);
  }

  // let Openid = storage.getStore('Openid')
  // if (Openid) {
  //   store.commit('updateOpenid', Openid);
  // }

  let Openid = null;
  if (process.env.NODE_ENV == 'development') {
    Openid = store.state.Openid;
  } else {
    // Openid = storage.getStore('Openid')
    Openid = sessionStorage.getItem("Openid"); 
    if (Openid) {
      store.commit('updateOpenid', Openid);
    }
  }

  let userInfo = store.state.userInfo;
  let Token = storage.getStore("Token");
  let isLogin = store.state.isLogin;
  if (!Openid && to.meta.isRoutes && to.path != '/authorize') {
    if (to.path != '/test' && to.path != '/openJNJUser') {
      storage.setStore('scope', 'snsapi_base');
      next({
        path: '/authorize',
        query: { scope: 'snsapi_base' }
      })
    } else {
      next();
    }
  } else {
    let JNJUserSource = sessionStorage.getItem("JNJUserSource");
    if(to.path != '/electronicPhoto' && to.path != '/JNJUser' && to.path != '/authorize' && !JNJUserSource) {
    if (!userInfo || !isLogin || !Token) {
      Vue.prototype.$axios.post('/Api/Api/Web/GetWxUser/GetCustmSta?OpenID=' + Openid).then(res => {
        if (res.RetCode == '10000') {
          let userInfo = res.Data;
          if (userInfo) {
            store.dispatch('setUserInfo', { userInfo: userInfo });
            store.dispatch('setLoginStatus', true);
            if (res.Data.Token) {
              storage.setStore('Token', res.Data.Token);
            }
          }
          
          if(userInfo.IsLogin) {
            next('/login');
          } else {
            next();
          }
        }
        // else if(res.RetMsg == "用户未授权！") {
        //   storage.setStore('scope', 'snsapi_userinfo');
        //   next({
        //     path: '/authorize',
        //     query: {scope: 'snsapi_userinfo'}
        //   })
        // }
        else {
          if (to.meta.role) {
            if (isLogin) {
              next();
            } else {
              Vue.prototype.$dialog.confirm({
                title: '提示',
                message: '您当前还未注册，请先去注册',
              }).then(() => {
                sessionStorage.setItem("redirectUrl", to.fullPath);
                next('/entrance');
              })
            }
          } else {
            next();
          }
        }
      })
    } else {
      if (to.meta.role) {
        if (!store.state.isLogin) {
          Vue.prototype.$dialog.confirm({
            title: '提示',
            message: '您当前还未注册，请先去注册',
          }).then(() => {
            sessionStorage.setItem("redirectUrl", to.fullPath);
            next('/entrance');
          })
        } else if (!storage.getStore("Token")) {
          Vue.prototype.$dialog.alert({
            title: '提示',
            message: '您当前还未登录，请先去登录',
          }).then(() => {
            next('/entrance');
          });
        } else {
          next();
        }
      } else {
        next();
      }
    }
    } else {
      next()
    }
  }
})

router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = 'E起爱缝合';
  }

  if (to.path != '/authorize') {
    let Openid = null;
    if (process.env.NODE_ENV == 'development') {
      Openid = store.state.Openid;
    } else {
      Openid = sessionStorage.getItem("Openid"); 
      if (Openid) {
        store.commit('updateOpenid', Openid);
      }
    }

    if(to.path != '/electronicPhoto' && to.path != '/JNJUser' && to.path != '/authorize') {
      Vue.prototype.$axios.post('/Api/Api/Web/GetWxUser/GetCustmSta?OpenID=' + Openid).then(res => {
        if(res.RetMsg == "用户未授权！") {
          storage.setStore('scope', 'snsapi_userinfo');
          router.push({
            path: '/authorize',
            query: {scope: 'snsapi_userinfo'}
          })
        }
      })
    }

    Vue.prototype.$axios.post('/Api/Api/Web/AccessLog/AddALog', {
      Openid: Openid,
      Url: to.path
    }).then(res => {})
  }
})

export default router;
