import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Openid: process.env.VUE_APP_Openid,
    userInfo: null,
    isLogin: false,
    navBars: [], // 头部科室菜单栏
    navbarIndex: 0, // 底部菜单栏索引
    homeTipsShow: true,
    xwTipsShow: true
  },
  mutations: {
    updateUserInfo(state, payload) {
      state.userInfo = payload.userInfo;
    },
    changeNavbarIndex(state, index) {
      state.navbarIndex = index;
    },
    updateNavbar(state, value) {
      state.navBars = value;
    },
    changeHomeTipsShow(state, value) {
      state.homeTipsShow = value;
    },
    updateLoginStatus(state, value) {
      state.isLogin = value;
    },
    updateOpenid(state, value) {
      state.Openid = value;
    },
    changeXWTipsShow(state, value) {
      state.xwTipsShow = value;
    },
  },
  actions: {
    setUserInfo({ commit }, params) {
      commit('updateUserInfo', { userInfo: params.userInfo });
    },
    setLoginStatus({ commit }, value) {
      commit('updateLoginStatus', value);
    },
    setOpenid({ commit }, value) {
      commit('updateOpenid', value);
    },
    setNavbar({ commit }, value) {
      commit('updateNavbar', value);
    },
  },
  modules: {},
});
